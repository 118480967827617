// polyfills
import objectFitImages from 'object-fit-images';

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import Swiper from 'swiper/js/swiper';
import svg4everybody from 'svg4everybody';
import Cookies from 'js-cookie';
import Glide from '@glidejs/glide';
import ResponsiveBootstrapToolkit from '@valtech/responsive-bootstrap-toolkit';
import scUtils from './lib/sitecore-utils';
import CookieHandler from './lib/cookies/cookie-handler';
import CookieConsent from './lib/cookies/cookie-consent';
import CookieConfig from './lib/cookies/cookie-config';
import requiredCookie from './lib/cookies/required-cookie.mixin';

// bootstrap dependencies
import 'popper.js';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';

// export vars
const viewport = new ResponsiveBootstrapToolkit();
const sitecoreUtils = scUtils;

document.addEventListener('DOMContentLoaded', () => {
  // enable svg
  svg4everybody();

  // polyfill for 'object-fit' css property
  objectFitImages();
});

// library exports
export {
  sitecoreUtils,
  viewport,
  Cookies,
  CookieConfig,
  CookieHandler,
  CookieConsent,
  requiredCookie,
  Glide,
  axios,
  Swiper,
  Vue,
  Vuex,
};
